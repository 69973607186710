<template>
  <div>
    <div
      style="height: calc(100vh - 145px); overflow-y: auto; overflow-x: hidden"
    >
      <Delivery v-if="isActiveTab('delivery-contract')" />
      <Shop v-else-if="isActiveTab('shop-config')" />
    </div>
    <v-divider class="mt-2"></v-divider>
    <div
      class="pt-2 pb-4 pb-md-2 white-space-nowrap overflow-x-auto overflow-y-hidden"
    >
      <v-btn
        :outlined="!isActiveTab('delivery-contract')"
        color="primary"
        small
        class="mr-2"
        @click="changeTab('delivery-contract')"
      >
        {{ $t("labels.delivery_contract") }}
      </v-btn>
      <v-btn
        :outlined="!isActiveTab('shop-config')"
        color="primary"
        small
        class="mr-2"
        @click="changeTab('shop-config')"
      >
        {{ $t("labels.shop") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  components: {
    Shop: () => import("@/components/delivery_shop/Shop"),
    Delivery: () => import("@/components/delivery_shop/Delivery"),
  },
  data: () => ({
    isLoading: false,
    tab: "delivery-contract",
  }),
  computed: {},
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    isActiveTab(tab) {
      return this.tab === tab;
    },
  },
};
</script>

<style scoped></style>
